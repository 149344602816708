body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.d-flex {
  display: flex;
}

.my-container {
  margin: 0 auto;
  width: 100%;
  max-width: 95%;
  padding: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tableBody th,
.tableBody td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  font-weight: bold;
}

.tableBody th {
  background-color: #f4f4f4;
}

 
/* img {
  width: 50px;
  height: 50px;
  margin: 5px;
} */

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 10fr));
  gap: 5px;
  
}

button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.edit-btn {
  background-color: #6c7ae0;
  color: white;
  transition: 0.3s;
}

.edit-btn:hover {
  transform: scale(0.9);
}

.delete-btn {
  background-color: #e06c6c;
  color: white;
  transition: 0.3s;
}

.delete-btn:hover {
  transform: scale(0.9);
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.pagination .page-item .page-link {
  color: #007bff;
  cursor: pointer;
}

.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.pagination .page-item .page-link:hover {
  background-color: #e9ecef;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48px, 2fr));
  gap: 5px;
  margin-top: 10px;  
  margin-right: 1px;
   
  
}


/* Button */

 

.show-images-btn {
  background-color: #6c7ae0;
  color: white;
  transition: 0.3s;
}

.show-images-btn:hover {
  transform: scale(0.9);
   
}

.show-btn{
    width: 100px;
    font-size: small;
    background-color: #000000;
    color: white;
}


 
 /* Loader CSS */
 @keyframes drop {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}

.lava-lamp {
  position: relative;
  width: 50px;
  height: 100px;
  background: #000;
  border-radius: 25px;
  overflow: hidden;
}

.bubble {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(to bottom, #e64980, #ff8787);
  border-radius: 50%;
  left: 15px;
  animation: drop 5s ease-in-out infinite;
}
.bubble1 {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(to bottom, #82c91e, #3bc9db);
  border-radius: 50%;
  left: 1px;
  animation: drop 3s ease-in-out infinite;
}
.bubble2 {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(to bottom, #7950f2, #f783ac);
  border-radius: 50%;
  left: 30px;
  animation: drop 4s ease-in-out infinite;
}
.bubble3 {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(to bottom, #4481eb, #04befe);
  border-radius: 50%;
  left: 20px;
  animation: drop 6s ease-in-out infinite;
}
