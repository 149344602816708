/* Navbar */

.navbar-index {
    z-index: 99;
    width: 99vw;
    margin-inline: auto;
  }
  .navBar {
    background-color: #eff1fa;
    box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2);
    height: 60px;
    padding-block: 5px;
    z-index: 99;
  }
  .navLogo {
    background: linear-gradient(to right, #fb0270, #020eff);
    -webkit-background-clip: text;
    background-clip: text;
    font-family:  serif;
    font-weight: 500;
    color: transparent;
    text-shadow: 2px 2px 2px gray;
    z-index: 99;
  }
  .navLogoImg {
    padding-bottom: 6px;
    height: 30px;
    width: 30px;
  }
  .card-img-top {
    flex-grow: 2;
  }
  .image-container {
    transform: scale(1.03);
  }
  
  /* nav Logo */
  
  .profile {
    height: 30px;
    width: 30px;
    object-fit: center;
    object-position: bottom;
    cursor: pointer !important;
  }
  
  .search {
    cursor: pointer;
    transition: linear 0.3s;
  }
  .hamberger {
    cursor: pointer;
    transition: linear 0.2s;
  }
  .fa-xmark {
    transform: scale(1.5);
  }
  .search:hover {
    transform: scale(1.1);
  }
  
  