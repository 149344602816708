 

 
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: add a semi-transparent background */
    z-index: 9999; /* Ensure it's above other content */
  }
  
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
  
  .loader-text {
    font-family: "Courier New", Courier, monospace;
    margin-top: 1rem;
  }
  

  /* Loader css Heart */

  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; /* black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .cssload-main {
    position: absolute;
    content: '';
    left: 50%;
    transform: translate(-100%, -240%);
    -o-transform: translate(-100%, -240%);
    -ms-transform: translate(-100%, -240%);
    -webkit-transform: translate(-100%, -240%);
    -moz-transform: translate(-100%, -240%);
    
  }
  
  .cssload-main * {
    font-size: 62px;
  }
  
  .cssload-heart {
    animation: cssload-heart 1.44s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heart 1.44s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heart 1.44s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heart 1.44s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heart 1.44s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
     
  }
  
  .cssload-heartL {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(252, 0, 101);
    background-color: rgb(252, 0, 101);
    content: '';
    position: absolute;
    display: block;
    border-radius: 100%;
    animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    transform: translate(-28px, -27px);
    -o-transform: translate(-28px, -27px);
    -ms-transform: translate(-28px, -27px);
    -webkit-transform: translate(-28px, -27px);
    -moz-transform: translate(-28px, -27px);
  }
  
  .cssload-heartR {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(252, 0, 101);
    background-color: rgb(252, 0, 101);
    content: '';
    position: absolute;
    display: block;
    border-radius: 100%;
    transform: translate(28px, -27px);
    -o-transform: translate(28px, -27px);
    -ms-transform: translate(28px, -27px);
    -webkit-transform: translate(28px, -27px);
    -moz-transform: translate(28px, -27px);
    animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
  }
  
  .cssload-square {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(252, 0, 101);
    background-color: rgb(252, 0, 101);
    position: relative;
    display: block;
    content: '';
    transform: scale(1) rotate(-45deg);
    -o-transform: scale(1) rotate(-45deg);
    -ms-transform: scale(1) rotate(-45deg);
    -webkit-transform: scale(1) rotate(-45deg);
    -moz-transform: scale(1) rotate(-45deg);
    animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
  }
  
  .cssload-shadow {
    top: 97px;
    left: 50%;
    content: '';
    position: relative;
    display: block;
    bottom: -.5em;
    width: 1em;
    height: .24em;
    background-color: rgb(215,215,215);
    border: 1px solid rgb(215,215,215);
    border-radius: 50%;
    animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
  }
  
  @keyframes cssload-square {
    50% {
      border-radius: 100%;
      transform: scale(0.5) rotate(-45deg);
    }
  
    100% {
      transform: scale(1) rotate(-45deg);
    }
  }
  
  @-o-keyframes cssload-square {
    50% {
      border-radius: 100%;
      -o-transform: scale(0.5) rotate(-45deg);
    }
  
    100% {
      -o-transform: scale(1) rotate(-45deg);
    }
  }
  
  @-ms-keyframes cssload-square {
    50% {
      border-radius: 100%;
      -ms-transform: scale(0.5) rotate(-45deg);
    }
  
    100% {
      -ms-transform: scale(1) rotate(-45deg);
    }
  }
  
  @-webkit-keyframes cssload-square {
    50% {
      border-radius: 100%;
      -webkit-transform: scale(0.5) rotate(-45deg);
    }
  
    100% {
      -webkit-transform: scale(1) rotate(-45deg);
    }
  }
  
  @-moz-keyframes cssload-square {
    50% {
      border-radius: 100%;
      -moz-transform: scale(0.5) rotate(-45deg);
    }
  
    100% {
      -moz-transform: scale(1) rotate(-45deg);
    }
  }
  
  @keyframes cssload-heart {
    50% {
     transform: rotate(720deg);
    }
  
    100% {
      transform: rotate(1440deg);
    }
  }
  
  @-o-keyframes cssload-heart {
    50% {
      -o-transform: rotate(720deg);
    }
  
    100% {
      -o-transform: rotate(1440deg);
    }
  }
  
  @-ms-keyframes cssload-heart {
    50% {
      -webkit-transform: rotate(720deg);
    }
  
    100% {
      -webkit-transform: rotate(1440deg);
    }
  }
  
  @-webkit-keyframes cssload-heart {
    50% {
      -moz-transform: rotate(720deg);
    }
  
    100% {
      -moz-transform: rotate(1440deg);
    }
  }
  
  @-moz-keyframes cssload-heart {
    50% {
      -moz-transform: rotate(360deg);
    }
  
    100% {
      -moz-transform: rotate(720deg);
    }
  }
  
  @keyframes cssload-heartL {
    60% {
      transform: scale(0.4);
    }
  }
  
  @-o-keyframes cssload-heartL {
    60% {
      -o-transform: scale(0.4);
    }
  }
  
  @-ms-keyframes cssload-heartL {
    60% {
      -ms-transform: scale(0.4);
    }
  }
  
  @-webkit-keyframes cssload-heartL {
    60% {
      -webkit-transform: scale(0.4);
    }
  }
  
  @-moz-keyframes cssload-heartL {
    60% {
      -moz-transform: scale(0.4);
    }
  }
  
  @keyframes cssload-heartR {
    40% {
      transform: scale(0.4);
    }
  }
  
  @-o-keyframes cssload-heartR {
    40% {
      -o-transform: scale(0.4);
    }
  }
  
  @-ms-keyframes cssload-heartR {
    40% {
      -ms-transform: scale(0.4);
    }
  }
  
  @-webkit-keyframes cssload-heartR {
    40% {
      -webkit-transform: scale(0.4);
    }
  }
  
  @-moz-keyframes cssload-heartR {
    40% {
      -moz-transform: scale(0.4);
    }
  }
  
  @keyframes cssload-shadow {
    50% {
      transform: scale(0.5);
      border-color: rgb(228,228,228);
    }
  }
  
  @-o-keyframes cssload-shadow {
    50% {
      -o-transform: scale(0.5);
      border-color: rgb(228,228,228);
    }
  }
  
  @-ms-keyframes cssload-shadow {
    50% {
      -ms-transform: scale(0.5);
      border-color: rgb(228,228,228);
    }
  }
  
  @-webkit-keyframes cssload-shadow {
    50% {
      -webkit-transform: scale(0.5);
      border-color: rgb(228,228,228);
    }
  }
  
  @-moz-keyframes cssload-shadow {
    50% {
      -moz-transform: scale(0.5);
      border-color: rgb(228,228,228);
    }
  }