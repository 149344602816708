

 


.add-post{
    /* filter: drop-shadow(2px 1px 2px red); */
    font-family:sans-serif ;
    font-weight: lighter;
    margin-bottom: 20px;
   
}