/* middleBar */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-block: 10px;
  backdrop-filter: blur(10px);
  z-index: 1040;
}

.centered-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  background-color: white;
  z-index: 1050;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  background: #e7e7e7;
}
.select-container {
  height: 5vh;
  overflow-x: hidden;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content button {
  margin-top: 10px;
  color: rgb(31, 1, 150);
}

.modal-buttons button {
  padding-inline: 30px;
  padding-block: 10px;
}

.searchBarXmark:hover {
  color: gray;
}

.overlay-black {
  background: transparent !important;
  border: none !important;
  height: 70% !important;
}
.swiper-pagination-bullet {
  background-color: whitesmoke !important;
}
.swiper-pagination-bullet-active {
  background-color: #e46fa4 !important;
}

@media screen and (max-width: 1024px) {
  .modal-content p ul li {
    font-size: 0.7rem;
  }
  .centered-div {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .centered-div {
    width: 60% !important;
  }
  .navLogo {
    font-size: 1.5rem;
  }
  /* modal Text */
  .textModal {
    text-align: center !important;
    font-size: 0.8rem !important;
  }
  .modal-content {
    padding-inline: 5px !important;
  }
  .modal-content button {
    margin-top: 0 !important;
  }
  .ModalLogo {
    width: 50px;
  }
  .carousel-wrapper {
    width: 100% !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.3rem !important;
    height: 36px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    /* color:transparent !important; */
  }
}

@media screen and (max-width: 425px) {
  .centered-div {
    width: 80% !important;
  }
  .textdescModal {
    font-size: 0.5rem !important;
  }
}
