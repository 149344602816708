.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}



/* Card Data */
.city-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 20px;
  margin-top: 0px;
  padding: 15px;
}

.mycard {
  background-color: #f8fbfe;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle {
  padding: 4px;
}

.box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

.card__content {
  padding: 10px 0;
}

.card__content h5 {
  font-size: large;
}

.button {
  background-color: red;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: green;
}

/* Pagination */

.pagination-container {
  display: flex;
  justify-content: center;

}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  display: block;
  padding: 10px 15px;
  border: 1px solid #ddd;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  background-color: white;
}

.pagination li a:hover {
  background-color: #f0f0f0;
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}


/* Filter */
.filter-container,
.per-page-container {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.filter-per-page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* Adjust as needed for spacing */
}

.filter-container label,
.per-page-container label {
  margin-right: 10px;
  color: #f8f9fa;
  /* Make label text light */
}

.filter-container select,
.per-page-container select {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #333;
}