 .all_data {
     padding: 10px;
     /* min-height: 100vh; */
     display: flex;
     justify-content: center;
     align-items: center;
     background: rgba(255, 255, 255, 0.938) center / cover;
 }

 .table_header {
     width: 100%;
     height: 10%;
     background-color: #fff4;
     padding: .8rem 1rem;
     display: flex;
     justify-content: center;
     background: rgba(138, 136, 136, 0.199);
     text-transform: uppercase;

 }

 .table_body {
     width: 95%;
     max-height: calc(89% - .8rem);
     background-color: #fffb;
     margin: .58rem auto;
     overflow: auto;
 }



 table,
 th,
 td {
     padding: 1rem;


 }

 thead tr {
     text-transform: uppercase;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-size: 18px;



 }

 /* thead tr th {
     background-color: rgb(105, 233, 233);
     border-radius: 18px;
     padding: 3px;
     text-align: center;


 } */

 .all_data .table {
     width: 82vw;
     height: 90vh;
     background-color: #fff5;
     backdrop-filter: blur(7px);
     box-shadow: 0 .4rem .8rem rgb(196, 196, 197);
     border-radius: 0.8rem;
     overflow: hidden;

 }

 .search_bar {
     display: flex;
     justify-content: center;
     align-items: center;
     padding-left: 10rem;

 }

 .serach_input {
     max-height: 1.9rem;
     cursor: pointer;
 }

 .serach_input::before {
     border: 1px solid red;
 }

 .no_data {
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 250px;
     border: 1px solid rgb(199, 199, 199);
     border-radius: 1.5rem;
     padding: 0.5rem;
     min-width: 200px;
     font-weight: 600;
     font-size: 20px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     text-transform: capitalize;
     background: rgba(250, 248, 248, 0.651) center / cover;
     box-sizing: 1px 50px 100px gray;

 }