.bold-btn{
    border-radius: 10%;
    width: 100px;
    height: 43px;
    margin-left: 50px;
    
}

.bold-icon{
    position: relative;
    bottom: 4.5px;
}