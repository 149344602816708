/* CheckOut.css */
.checkout_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

}

.checkout_header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.check_card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.check_img {
    width: 100%;
    height: auto;
}

.check_right {
    padding: 20px;
}

.check_head {
    font-size: 1.2rem;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: #be206b;
    ;
}

.check_adId,
.check_package {
    font-size: 1rem;
    display: block;
    margin-bottom: 10px;
    color: #000000;
    font-weight: 650;
}

.check_common {
    font-weight: bold;
}

.wallet_card {

    padding: 20px;
    border-top: 1px solid #ddd;
    font-weight: 500;
    background: antiquewhite;
    box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.5);
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.check_packName {
    color: rgb(24, 69, 190);
}

.check_wallet_title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    background-color: #be206b;
    color: white;
    padding: 5px 19px;
    border-radius: 18px;
}

.avl_bal,
.usable_bal,
.remain_bal {
    font-weight: bold;
    margin-left: 5px;
    color: rgb(24, 69, 190);
    font-size: 20px;
    padding-left: 10px;
}

.avl_wal_head {
    font-size: large;
    font-weight: 700;
}

.check_msg {
    font-size: 0.9rem;
    color: #fa0909;
    margin-top: 10px;
    display: block;
    text-align: center;
    font-weight: 600;

}

.check_condition_btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.proceed_btn,
.add_money_btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.proceed_btn {
    background-color: #4caf50;
    color: white;
}

.proceed_btn:hover {
    background-color: #45a049;
}

.add_money_btn {
    background-color: #058119;
    color: white;
    text-transform: capitalize;
}

.add_money_btn:hover {
    background-color: #e53935;
}

/* Responsive Styles */
@media (min-width: 768px) {
    .checkout_container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        height: 90vh;

    }

    .check_card {
        flex-direction: row;
    }

    .check_img {
        width: 20%;
        height: 300px;
    }

    .check_right {
        width: 50%;
        padding: 20px;
    }

    .check_adId,
    .check_package {
        font-size: 1rem;
        display: block;
        margin-bottom: 10px;
    }



}

@media (max-width: 767px) {
    .checkout_container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;

    }

    .check_card {
        flex-direction: column;
    }

    .check_img {
        width: 100%;
        height: 350px;
    }

    .check_right {
        width: 100%;
        padding: 15px;
    }

    .checkout_container {
        padding: 10px;
    }

    .checkout_header {
        font-size: 1.5rem;
    }

    .check_adId,
    .check_package {
        font-size: 1rem;
        display: block;
        margin-bottom: 10px;
    }
}