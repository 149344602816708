body {
  font-family: Arial, sans-serif;

}

.sidenavigationBar {
  width: 350px;
  background-color: #333;
  color: #fff;
  min-height: 100vh;
  /* Changed height to min-height */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.user-info {
  padding: 10px;
  cursor: pointer;
  position: relative;
}


.user-account-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 33px 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.user-info .show {
  display: block;
}

/* .user-info:hover .user-account-dropdown {
    display: block;
} */



.user-avatar {
  width: 50px;
  height: 50px;
  background-color: #555;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.user-account a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-top: 5px;
}

.menu-item {
  padding: 15px 0;
  cursor: pointer;
  position: relative;
  /* Added position relative */

}

.menu-item a {
  color: #fff;
  text-decoration: none;
}

.menu-item span {
  color: #fff;
}

.menu-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.dropdown {
  position: relative;
}

.dropdown-content {
  position: relative;
  top: 100%;
  left: 0;
  display: none;
  padding-left: 27px;
  display: block;


}



.dropdown-content a {

  display: block;
  margin: 5px 0;
}

.downArrow {
  margin-left: auto;

}

.downArraow {
  margin-left: 5rem;
}