
.card a{
  text-decoration: none;
  color: #D9D9D9;
  cursor: pointer;
}


.card-1 {
  background-color: #FB8D00;
  color: white;
}

.card-1 i {
  margin-top: 20px;
}

.card-2 {
  background-color: #08CC95;
  color: white;
}

.card-2 .check-icon {
  display: inline-block;
  padding: 20px;
  margin-top: 8px;
  text-align: center;
}

.card-3 {
  background-color: #186DDF;
  color: white;
}

.card-4 {
  background-color: #000000;
  color: white;
}

/* Common card class */
.common-card {
  height: 100px; /* Adjust the height as needed */
  width: 100%; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Card-digit */
.card-digit {
  font-size: xx-large;
}

/* Ensuring equal height and width for all cards */
.col-lg-3 {
  max-width: 300px; /* Adjust the width to ensure consistency */
}



/* Cards */
.my-card {
     height: 400px;
}

.post-Listing{
  background-color: #D9D9D9;
}

.view-all-list{
  background-color: #3A8BF7;
}

.btn{
  transition: 0.3s;
}

.btn:hover{
  transform: scale(0.9);
}

.blue-circle{
  width: 13px;
  height: 13px;
  background-color: #3A8BF7;
  border-radius: 50%;
}

.blue-circle2{
  width: 13px;
  height: 13px;
  background-color: #D9D9D9;
  border-radius: 50%;
}