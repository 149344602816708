* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

body {
  background: #eff1fa;
  overflow-x: hidden !important;
}

.separatePosts {
  animation: LoadingAnimation 0.75s;
  animation-iteration-count: 1;
}

.no-transition * {
  transition: none !important;
}

/* side Bar */

.content {
  padding: 20px;
}

/* Add this to your App.css or ResponsiveApp.css */
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100vh;
  background: #eff1fa !important;
  color: black;
  transition: right 0.3s ease;
  z-index: 1000;
  padding-top: 60px;
  font-weight: 500;
  overflow-y: scroll;
}
.sidebar_ul hr {
  color: gray;
}

.sidebar.open {
  right: 0;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 36px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-content ul {
  list-style: none;
  padding: 0;
}

.sidebar-content ul li {
  margin: 15px 0;
}

.sidebar-content ul li a {
  color: black;
  text-decoration: none;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(5px);
  display: none;
}

.blur-background.active {
  display: block;
}

.sidebar_hover:hover {
  color: rgb(172, 31, 233);
  transition: 0.2s linear;
}

/* Card Section */

.card_image {
  max-height: 20rem;
  min-height: 19rem;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: none !important;
  cursor: pointer;
}
.card_banner {
  background-color: whitesmoke;
  padding-inline: 2px;
  padding-block: 2px;
  border-radius: 10px;
  overflow: hidden;
  max-width: 20rem;
  min-width: 15rem;
  background: transparent;
  border: none;
  transition: 0.3s linear;
  padding: 2px;
}
.card_banner:hover {
  background-color: rgba(255, 255, 255, 0.4);
}




/* Categories Section */
.selectCategories {
  height: 35px;
  width: 516px !important;
}

/* Post Ad section */

.postAdWidth {
  width: 40%;
}
.inCallWidth {
  width: 33%;
}
.inputCity {
  width: 25% !important;
}

/* Profile Section */

.profileImage {
  width: 90px;
  height: 90px;
}
.AllPosts {
  color: #103652;
  padding: 0 !important;
}

/* Activity Button */

.activityBtn:active {
  background-color: #103652;
}
.tableRow {
  background-color: #103652 !important;
  border-color: white;
}

/* Separate Items Page */

.ItemsShortScreen {
  display: none !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev {
  color: gray !important;
}

/* App.css or your preferred CSS file */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.modal-images {
  width: 40%;
}

.modal-content {
  width: 80%;
  height: fit-content;
  overflow-y: scroll;
  /* background:white; */
  /* background:transparent; */
  padding: 0.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-block: 20px;
  overflow-y: hidden;
  z-index: 999;
  padding-block: 20px;
}

.blur-background {
  filter: blur(8px);
}

/* Modal Css*/

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content button {
  margin-top: 10px;
  color: rgb(31, 1, 150);
}

.modal-buttons button {
  padding-inline: 30px;
  padding-block: 10px;
}

.ModalLogo {
  width: 100px;
}

.searchBarXmark:hover {
  color: gray;
}

.overlay-black {
  background: transparent !important;
  border: none !important;
  height: 70% !important;
}
.swiper-pagination-bullet {
  background-color: whitesmoke !important;
}
.swiper-pagination-bullet-active {
  background-color: #e46fa4 !important;
}


/* Image count and Tap to big */
.image-container {
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute !important;
  bottom: 5px;
  left: 10px;
  z-index: 99;
}
.TapToView {
  position: absolute !important;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  z-index: 1;
  text-shadow: 2px 2px 2px gray;
  cursor: pointer !important;
}

/* BreadCumb In Posts  */

.breadcrumb-item + .breadcrumb-item::before {
  content:"/";
  font-size: 18px;
  color: #f35199;
  font-weight: bolder !important;
}


.Errorpage{
  transform:scale(0.8);
  height:400px;
}
/* loader Css */

.loader {
  overflow: hidden;
  position: relative;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
  animation: gradient-animation 1.2s linear infinite;
}

.loader .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader .wrapper > div {
  background-color: #cacaca;
}

.loader .circle {
  width: 318px;
  height: 300px;
}

.loader .line-1,
.loader .line-2,
.loader .line-3,
.loader .line-4 {
  height: 10px;
  background-color: #cacaca;
}

.loader .line-1 {
  width: 90px;
  height:30px;
  top: 310px;
  left: 10px;
  position: absolute;
}

.loader .line-2 {
  width: 150px;
  top: 350px;
  left: 10px;
  position: absolute;
}

.loader .line-3 {
  width: 90%;
  top: 380px;
  left: 10px;
  position: absolute;
}

.loader .line-4 {
  width: 92%;
  top: 80px;
  left: 0;
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

